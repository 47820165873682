import React from 'react';
import { FaCss3, FaHtml5, FaJsSquare, FaSass, FaNodeJs } from "react-icons/fa";
import SkillCard from '../../Components/SkillCard';

const htmlColor = "#f15025";
const cssColor = "#264de4 ";
const jsColor = "#f7df1e";
const nodeJSColor = "#3c873a  ";
const sassColor = "#cc6699 ";

const skills = [
    {
        name: "HTML 5",
        icon: <FaHtml5 size="48" color={htmlColor} />,
        color: htmlColor,
        progress: 90,
        ringColor: "ring-html",
    },
    {
        name: "CSS 3",
        icon: <FaCss3 size="48" color={cssColor} />,
        color: cssColor,
        progress: 75,
        ringColor: "ring-css",
    },
    {
        name: "JavaScript",
        icon: <FaJsSquare size="48" color={jsColor} />,
        color: jsColor,
        progress: 60,
        ringColor: "ring-js",
    },
    {
        name: "Node JS",
        icon: <FaNodeJs size="48" color={nodeJSColor} />,
        color: nodeJSColor,
        progress: 50,
        ringColor: "ring-node",
    },
    {
        name: "SASS/SCSS",
        icon: <FaSass size="48" color={sassColor} />,
        color: sassColor,
        progress: 70,
        ringColor: "ring-sass",
    }
]


export default function Skills(props) {
    const {skillsRef} = props;

    return (
        <div ref={skillsRef} id="portfolio" className="right-0 left-0 flex flex-col justify-center items-center h-screen min-h-screen py-20 bg-gray-50" style={{minHeight: "fit-content"}}>
            <div className="flex flex-col justify-center items-center">
                <h1 className="text-4xl md:text-6xl font-bold text-gray-800">Skills</h1>
                {/* Title Decorator */}
                <div className="flex justify-center items-center mt-2">
                    <span className="p-0.5 rounded-full bg-gray-800 mr-1"></span>
                    <span className="p-0.5 rounded-full bg-gray-800 mr-1"></span>
                    <span className="p-0.5 rounded-full bg-gray-800 mr-1"></span>
                    <span className="p-0.5 rounded-full bg-gray-800 w-24 md:w-36"></span>
                </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-8 md:w-9/12 my-10 md:my-20">
                {
                    skills.map((skill, index) => (
                        <SkillCard 
                            key={index} 
                            name={skill.name} 
                            icon={skill.icon} 
                            color={skill.color}
                            progress={skill.progress}
                            ringColor={skill.ringColor}
                        />
                    ))
                }
            </div>

        </div>
    )
}
