import React, { createRef, useEffect } from 'react';
import { gsap } from 'gsap';

export default function Intro(props) {
    const hiRef = createRef();
    const nameRef = createRef();
    const titleRef = createRef();
    const quoteRef = createRef();

    const {introRef} = props;
    
    useEffect(() => {

        let tl = gsap.timeline();
        tl.fromTo([hiRef.current, nameRef.current, titleRef.current], {x: -100, opacity: 0}, {x: 0, opacity: 1, duration: 1})
        .fromTo(quoteRef.current, {y: 100, opacity: 0}, {y: 0, opacity: 1, duration: 1})

    }, [hiRef, nameRef, titleRef, quoteRef]);

    return (
        <div ref={introRef} id="intro" className="relative -z-20 h-screen flex flex-col items-center justify-center bg-white" style={{minHeight: "500px"}}>

            <img className="absolute left-0 right-0 top-0 -z-10" src={process.env.PUBLIC_URL + "./wave.svg"} />

            {/* Image */}
            <div className="flex justify-center items-center mb-6 md:mb-10 w-80">
                <svg className="w-64 md:w-full" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" width="100%" id="blobSvg">
                    <clipPath id="blob">
                        <path d="M451,321Q419,392,355.5,439Q292,486,210,473.5Q128,461,89,392Q50,323,33,244Q16,165,78.5,113.5Q141,62,213.5,55Q286,48,359.5,72Q433,96,458,173Q483,250,451,321Z" fill="#2563EB" />
                    </clipPath>
                    <image clipPath="url(#blob)" href="https://picsum.photos/id/1068/400" width="100%" height="100%" />
                    {/* <image clipPath="url(#blob)" href={process.env.PUBLIC_URL + "./mypic.jpg"} width="100%" height="100%" /> */}
                </svg>
            </div>

            {/* Intro */}
            <div className="flex flex-col justify-center items-center px-6 md:px-4">
                <div className="text-center mb-12">
                    <div ref={hiRef} className="flex justify-center text-3xl md:text-5xl font-medium text-gray-800">
                        <span>Hi, </span>
                    </div>
                    
                    <h1 ref={nameRef} className="text-4xl md:text-6xl font-bold">
                        <span className="text-gray-800">I am </span> 
                        <span className="bg-blue-200 px-2 rounded text-blue-600 leading-relaxed">Abdul Rafique</span>
                    </h1>
                    
                    <h3 ref={titleRef} className="text-2xl md:text-3xl text-gray-800 font-semibold"> {"<"} Front End Developer {"/>"}</h3>
                </div>
            </div>

            {/* Scroll To */}
            {/* <div className="absolute z-10 right-0 bottom-0 m-10">
                <FaArrowDown size="36" className="text-gray-800" />
            </div> */}

        </div>
    )
}
