import React, {useEffect} from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
// import Navbar from './Components/Navbar';
// import Sidebar from './Components/Sidebar'

import Home from './Pages/index';

function App() {
  
  useEffect(() => {
    return (function(d,t) {
        var BASE_URL="https://app.chatwoot.com";
        var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
        g.src=BASE_URL+"/packs/js/sdk.js";
        s.parentNode.insertBefore(g,s);
        g.onload=function(){
          window.chatwootSDK.run({
            websiteToken: 'wtcxP5CpZp2eG7fRtVFH6qLQ',
            baseUrl: BASE_URL
          })
        }
      })(document,"script");
  }, []);

  return (
      <Router>
        <Switch>
          {/* Routes */}
          <Route exact path="/" component={Home} />
        </Switch>
      </Router>
  );
}

export default App;
