import React from 'react'

export default function CarouselCard(props) {

    const {index, name, imgSrc} = props;

    return (
        <div key={index} className="relative rounded-lg mx-2 cursor-pointer" style={{minWidth: "200px", maxWidth: "500px"}}>
            <img className="w-72 md:w-full rounded-lg" src={imgSrc} alt="" />
            <div className="absolute bottom-0 flex justify-center items-center bg-gray-900 bg-opacity-80 w-full py-0.5 md:py-2 rounded-b-lg">
                <h3 className="text-xs md:text-lg font-semibold text-emerland-300">{name}</h3>
            </div>
        </div>
    )
}
