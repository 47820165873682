import React, { createRef } from 'react';
import { HiMenuAlt1 } from "react-icons/hi";

// Components
import Intro from './Sections/Intro';
import About from './Sections/About';
import Portfolio from './Sections/Portfolio';
import Skills from './Sections/Skills';

export default function Home() {
    const containerRef = createRef();
    const menuBtnRef = createRef();
    const introRef = createRef();
    const aboutRef = createRef();
    const portfolioRef = createRef();
    const skillsRef = createRef();

    return (
        <div ref={containerRef} className="h-screen overflow-y-scroll">
            <button ref={menuBtnRef} className="absolute top-0 left-0 m-2 md:m-10 text-white focus:outline-none">
                <HiMenuAlt1 size="36" />
            </button>

            <Intro introRef={introRef} />
            <About aboutRef={aboutRef} />
            <Portfolio portfolioRef={portfolioRef} />
            <Skills skillsRef={skillsRef} />
        </div>
    )
}
