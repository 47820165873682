import React, { createRef } from 'react';

export default function SkillCard(props) {
    const progressBarRef = createRef();
    const {index, name, icon, color, progress, ringColor} = props;

    return (
        <div key={index} className={`px-5 py-3 col-span-1 ring ${ringColor} bg-gray-100 flex items-center rounded-xl`}>
            {icon}
            
            <div className="flex flex-col w-full ml-4">
                <h2 className="text-lg lg:text-2xl font-semibold" style={{color: color}}>{name}</h2>
                <div className="flex flex-col items-end">
                    <span className="font-semibold">{progress}%</span>
                    <div className="w-full py-1 bg-gray-300 rounded-full relative">
                        <span ref={progressBarRef} className="py-1 rounded-full absolute top-0 left-0" style={{width: `${progress}%`, background: color}}></span>
                    </div>
                </div>
            </div>
        </div>
    )
}
