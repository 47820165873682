import React from 'react';
import Slider from 'react-slick';
import CarouselCard from '../../Components/CarouselCard';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './style.css';

const projects = [
    {
        name: "Project 1",
        img: "https://picsum.photos/id/119/1200/700"
    },
    {
        name: "Project 2",
        img: "https://picsum.photos/id/128/1200/700"
    },
    {
        name: "Project 3",
        img: "https://picsum.photos/id/13/1200/700"
    },
    {
        name: "Project 4",
        img: "https://picsum.photos/id/96/1200/700"
    }
]

const settings = {
    className:"variableWidth", 
    accessibility: true,
    dots: true,
    arrows: false,
    slidesToShow:1, 
    adaptiveHeight: true, 
    centerMode: true,
    centerPadding: "70px",
    variableWidth: true,
    draggable: true,
    focusOnSelect: true,
    responsive:[
        {
            breakpoint: 1400, 
            settings: {
                centerPadding: "70px"
            }
        }
    ]
}

export default function Portfolio(props) {
    const {portfolioRef} = props;

    return (
        <div ref={portfolioRef} id="portfolio" className="flex flex-col items-center justify-center h-screen min-h-screen bg-white" style={{minHeight: "500px"}}>
            <div className="flex flex-col justify-center items-center">
                <h1 className="text-4xl md:text-6xl font-bold text-gray-800">Portfolio</h1>
                {/* Title Decorator */}
                <div className="flex justify-center items-center mt-2">
                    <span className="p-0.5 rounded-full bg-gray-800 mr-1"></span>
                    <span className="p-0.5 rounded-full bg-gray-800 mr-1"></span>
                    <span className="p-0.5 rounded-full bg-gray-800 mr-1"></span>
                    <span className="p-0.5 rounded-full bg-gray-800 w-24 md:w-36"></span>
                </div>
            </div>

            <div className="w-9/12 md:w-4/6 h-58 rounded-lg my-10 md:my-16">
                <Slider {...settings}>
                    {
                        projects.map((proj, index) => (
                            <CarouselCard key={index} name={proj.name} imgSrc={proj.img} />
                        ))
                    }
                </Slider>
            </div>

            <button className="bg-gray-800 text-white px-4 py-3 mr-4 rounded-sm text-xl hover:ring-4 ring-gray-400">See All</button>
            
        </div>
    )
}
