import React, { createRef, useEffect } from 'react';
import gsap from 'gsap';

export default function About(props) {
    const aboutTxtRef = createRef();
    const meTxtRef = createRef();
    const dotRef1 = createRef();
    const dotRef2 = createRef();
    const dotRef3 = createRef();
    const lineRef = createRef();

    const {aboutRef} = props;

    useEffect(() => {
        const tl = gsap.timeline();
        
        tl.fromTo(dotRef1.current, {scale: 0, duration: 0.5}, {scale: 1, duration: 0.5})
        .fromTo(dotRef2.current, {scale: 0, duration: 0.5}, {scale: 1, duration: 0.5})
        .fromTo(dotRef3.current, {scale: 0, duration: 0.5}, {scale: 1, duration: 0.5})
        .fromTo(lineRef.current, {transformOrigin: "left", scaleX: 0, duration: 1}, {transformOrigin: "left", scaleX: 1, duration: 1})

    }, [dotRef1, dotRef2, dotRef3, lineRef]);

    return (
        <div ref={aboutRef} id="about" className="flex flex-col justify-center items-center p-6 h-screen min-h-full py-20 bg-white"style={{minHeight: "500px"}}>
            <div className="flex flex-col justify-center items-center p-4">
                {/* Section Title */}
                <h1 className="text-4xl md:text-6xl">
                    <span ref={aboutTxtRef} className="font-bold text-gray-800 mr-2">About</span> 
                    <span ref={meTxtRef} className="font-light text-gray-800">Me</span>
                </h1>
                {/* Title Decorator */}
                <div className="flex justify-center items-center mt-2">
                    <span ref={dotRef1} className="p-0.5 rounded-full bg-gray-800 mr-1"></span>
                    <span ref={dotRef2} className="p-0.5 rounded-full bg-gray-800 mr-1"></span>
                    <span ref={dotRef3} className="p-0.5 rounded-full bg-gray-800 mr-1"></span>
                    <span ref={lineRef} className="p-0.5 rounded-full bg-gray-800 w-24 md:w-36"></span>
                </div>
            </div>

            <div className="flex justify-center items-center px-16 w-5/6 my-10">
                <p className="text-2xl leading-normal md:text-4xl text-gray-800 text-center">
                I am a 
                <span className="font-semibold bg-pink-300 px-1 rounded-sm mx-1 text-pink-600">Web</span>
                and 
                <span className="font-semibold bg-pink-300 px-1 rounded-sm mx-1 text-pink-600">Mobile App Developer</span>.
                I have 2+ years of experience. Most of the work I have done as front-end developer.
                </p>
            </div>

            <button className="bg-gray-800 text-white px-4 py-3 rounded-sm text-xl hover:ring-4 ring-gray-400">Read more</button>

        </div>
    )
}
